import { TbArrowUpRight } from 'react-icons/tb'
import { Link } from 'routes/components/Shared'
import { CompaniesDataType } from './companiesData'
import clsx from 'clsx'
import classes from './Companies.module.scss'

export const CompaniesCard = ({ card }: { card: CompaniesDataType }) => {
  const { topic, href, description, logo, status } = card

  return (
    <div className={clsx(classes.card, status && classes.card__active)}>
      <div className={classes.card_header}>
        <p className={classes.card_topic}>{topic}</p>
        <Link
          aria-label={description}
          className={classes.card_link}
          href={href}
        >
          {status && <TbArrowUpRight className={classes.card_icon} />}
        </Link>
      </div>

      <div className={classes.card_content}>
        <p className={classes.card_description}>{description}</p>
        <img
          src={logo.src}
          width={251}
          height={55}
          loading='lazy'
          alt={topic}
          className={classes.card_image}
        />
      </div>
    </div>
  )
}
