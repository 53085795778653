import { ReactNode } from 'react'
import { LINKS } from 'routes/constants'
import { BadgesG2 } from 'routes/components/Features/BadgesG2/BadgesG2'
import { Link } from 'routes/components/Shared'
import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { companiesData } from './companiesData'
import { CompaniesCard } from './CompaniesCard'
import { CustomerLogos } from './CustomerLogos/CustomerLogos'
import clsx from 'clsx'
import classes from './Companies.module.scss'

export const Companies = ({
  title,
  subtitle,
  className,
}: {
  title?: string
  subtitle?: ReactNode
  className?: string
}) => {
  return (
    <section className={clsx('flex-column', classes.section, className)}>
      <h2 className={clsx('page__title', classes.title)}>
        {title || 'The best SaaS companies are building with us'}
      </h2>

      <p className={clsx('page__subtitle', classes.subtitle)}>
        {subtitle ||
          'The #1 choice for forward-thinking SaaS companies. From startups to enterprises, any use case, any SaaS category'}
      </p>

      <Slider />

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href={LINKS.SOLUTIONS.HEAD}
        >
          Explore Solutions
        </Link>
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.CASE_STUDIES}
        >
          Read Case Studies
        </Link>
      </div>
      <CustomerLogos />
      <BadgesG2 className={classes.badges} />
    </section>
  )
}

function Slider() {
  const { slidesToRender, carouselSettings } = useCarousel(companiesData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <CompaniesCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
