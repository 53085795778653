import { customerLogosData } from './customerLogosData'
import { CustomerLogosCard } from './CustomerLogosCard'
import clsx from 'clsx'
import classes from './CustomerLogos.module.scss'

export const CustomerLogos = () => {
  return (
    <div className={classes.wrapper}>
      <p className={clsx('page__subtitle', classes.subtitle)}>
        Trusted by 100+ SaaS companies
      </p>

      <div className={classes.content}>
        <ul className={classes.list}>
          {customerLogosData.map((item, idx) => (
            <CustomerLogosCard key={idx} item={item} />
          ))}
        </ul>
      </div>
    </div>
  )
}
