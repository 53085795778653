import { StaticImageData } from 'next/image'
import SequenceLogo from './logo-sequence.svg'
import StoryblockLogo from './logo-storyblock.svg'
import WhalesyncLogo from './logo-whalesync.svg'

export type CompaniesDataType = {
  topic: string
  href: string
  description: string
  logo: StaticImageData
  status: boolean
}

export const companiesData: CompaniesDataType[] = [
  {
    topic: 'Fintech',
    href: '/articles/case-studies/sequence-hq-implements-integration-apps-framework',
    description:
      'Sequence is a platform for streamlining pricing, billing, and invoicing workflows for B2B SaaS companies.',
    logo: SequenceLogo,
    status: true,
  },
  {
    topic: 'Data integration',
    href: '/articles/case-studies/integrations-for-data-sync-platform-whalesync',
    description:
      'WhaleSync is a data integration platform. They accelerated their connector roadmap with AI.',
    logo: WhalesyncLogo,
    status: true,
  },
  {
    topic: 'CMS',
    href: '/articles/case-studies/storyblok-cms-uses-integration-app-as-their-integration-infrastructure-provider',
    description:
      'Storyblok is a leading Content Management System (CMS) in the SaaS market. Learn how they upgraded their integration infrastructure.',
    logo: StoryblockLogo,
    status: true,
  },
]
