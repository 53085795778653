import { ReactNode } from 'react'
import { BestSupportBadges } from './Badges/Badges'
import { BestSupportReviews } from './Reviews/Reviews'
import clsx from 'clsx'
import classes from './BestSupport.module.scss'

interface BestSupportProps {
  isPageSection?: boolean
  subtitle?: ReactNode
  className?: string
}

export const BestSupport: React.FC<BestSupportProps> = ({
  isPageSection = true,
  subtitle,
  className,
}) => {
  return (
    <section className={clsx({ page__section: isPageSection }, className)}>
      <div
        className={clsx(
          classes.wrapper,
          isPageSection && classes.wrapper__inset,
        )}
      >
        <BestSupportBadges className={classes.badges} subtitle={subtitle} />
        <BestSupportReviews />
      </div>
    </section>
  )
}
