import { Link } from 'routes/components/Shared'
import { CustomerLogosDataType } from './customerLogosData'
import classes from './CustomerLogos.module.scss'

export const CustomerLogosCard = ({
  item,
}: {
  item: CustomerLogosDataType
}) => {
  const { name, url, Logo } = item

  return (
    <li className={classes.item}>
      <Link href={url} target='_blank' className={classes.link}>
        <Logo aria-labelledby={name} />
      </Link>
    </li>
  )
}
