import { ReactNode } from 'react'
import SupportSummer from './best-support-summer.svg'
import SupportSpring from './best-support-spring.svg'
import SupportFall from './best-support-fall.svg'
import clsx from 'clsx'
import classes from './Badges.module.scss'

export const BestSupportBadges = ({
  subtitle,
  className,
}: {
  subtitle?: ReactNode
  className?: string
}) => {
  const defaultSubtitle =
    'Our world-class solutions team is just one Slack message away. We help you understand complex APIs, build robust integration logic, and keep your customer integrations running smoothly'

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.heading}>
        <h2 className={clsx('page__title', classes.title)}>
          Best support and services on the market
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          {subtitle || defaultSubtitle}
        </p>
      </div>
      <div className={classes.awards}>
        <img
          src={SupportSpring.src}
          alt='best support spring 2024 award'
          loading='lazy'
          className={classes.image}
        />
        <img
          src={SupportSummer.src}
          alt='best support summer 2024 award'
          loading='lazy'
          className={classes.image}
        />
        <img
          src={SupportFall.src}
          alt='best support fall 2024 award'
          loading='lazy'
          className={classes.image}
        />
      </div>
    </div>
  )
}
